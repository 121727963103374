import React from "react"
import Slider from "react-slick"

import content_fr from '../../content/testimonials_fr.yaml'
import content_en from '../../content/testimonials.yaml'
import content_es from '../../content/testimonials_es.yaml'

if (typeof window !== `undefined`){
  const currentpath=window.location.pathname; 
  if (currentpath.indexOf("fr")>=0){
      var content=content_fr;
  }
  else if(currentpath.indexOf("es")>=0){
    content=content_es;
  }
  else{
    content=content_en;
  }
}
else{
  content=content_en;
}
const MySlider = () =>{

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    draggable: false,
    appendDots: dots => (
      <ol> {dots} </ol>
    ),
    customPaging: i => (
      <button>
        {i + 1}
      </button>
    ),
  }

  return (
    <section id="testimonials">
      <div className="row content">
        {/* <span><i className="quote-left fa"><FontAwesomeIcon icon={ faQuoteLeft } /></i></span> */}
        <div className="text-container">
{/*           <div className="twelve columns">
            <h1>{ content.title }</h1>
          </div> */}
          <div className="twelve columns flex-container">
          <Slider {...settings} style={{ userSelect: 'text' }}>
            { content.quotes.map((quote, index) => (
              <div>
                <blockquote>
                  <p>{ quote.text }</p>
                  {/* <cite>{ quote.cite }</cite> */}
                </blockquote>
              </div>
            ))}
          </Slider>
          </div>

        </div>
        {/* <span><i className="quote-right fa"><FontAwesomeIcon icon={ faQuoteRight } /></i></span> */}
      </div>
    </section>
  )
}

export default MySlider
