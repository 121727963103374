import React from "react"
//import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import content_fr from "../../content/pricing_fr.yaml"
import content_en from "../../content/pricing.yaml"
import content_es from "../../content/pricing_es.yaml"

if (typeof window !== `undefined`){
  const currentpath=window.location.pathname; 
  if (currentpath.indexOf("fr")>=0){
      var content=content_fr;
  }
  else if(currentpath.indexOf("es")>=0){
    content=content_es;
  }
  else{
    content=content_en;
  }
}
else{
  content=content_en;
}


export default () => {

  library.add(fas)

  return (
    <section id="pricing">
      <div className="row section-head">
        <h1>{ content.title }</h1>
        <p>{ content.body }</p>
      </div>

      <div className="row">
        <div className="pricing-tables bgrid-quarters s-bgrid-halves">
          { content.plans.map((plan, index) =>
            <div className="column">
              <div className="price-block">
                <h3 className="plan-title">
                  <i className="fa" aria-hidden="true"><FontAwesomeIcon icon={ plan.fa } /></i>
                  <div className="plan-title-text">
                  { plan.title }
                  </div>
                </h3>
               {/*  <p className="plan-price">
                  { plan.price }
                  <span>{ plan.per }</span>
                </p>
 */}
                <ul className="features">
                  { plan.features.map((value, index) =>
                    <li>{ value }</li>
                  )}
                </ul>
                <footer className="plan-sign-up">
                  <a className="button" href={ plan.signup.to }>
                    { plan.signup.label }
                  </a>
                </footer>
              </div>
            </div>
          )
          }
        </div>
      </div>
    </section>
  )
}
