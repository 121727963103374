import React, {useEffect, useState} from "react"


import content_fr from '../../content/subscribe_fr.yaml'
import content_en from '../../content/subscribe.yaml'
import content_es from '../../content/subscribe_es.yaml'

if (typeof window !== `undefined`){
  const currentpath=window.location.pathname; 
  if (currentpath.indexOf("fr")>=0){
      var content=content_fr;
  }
  else if(currentpath.indexOf("es")>=0){
    content=content_es;
  }
  else{
    content=content_en;
  }
}
else{
  content=content_en;
}



const Subscribe = () => {
  const [descrform, SetDescrform]=useState();
  useEffect(() => {
    if ((typeof window.location.pathname !== `undefined`)||(typeof content !== `undefined`)){
      if (window.location.pathname.indexOf("fr")>=0){
          //cgu_ref.current='/fr/cgu/';
          SetDescrform(content.description)
      }
      else if(window.location.pathname.indexOf("es")>=0){
        SetDescrform(content.description)
      }
      else{
        SetDescrform(content.description)
      }
    }
  },[])


return(
  <section id="subscribe">
    <div className="row section-head">
      <div className="twelve columns">
        <h1>{ content.title }</h1>
        <p>{ content.body }</p>
    </div>
  </div>


  <div className="row">
    <div className="twelve columns">
      <div id="mc_embed_signup">
      <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="contact" />
      <p class="hidden">
         <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
      </p>
        <p>
          <label>{ content.company}: <input type="text" name="company-name" /></label>   
        </p>
        <p>
          <label>{ content.contact}: * <input type="text" name="name" required/></label>   
        </p>
        <p>
          <label>{ content.email}: * <input type="email" name="email" required/></label>
        </p>
        <p>
          <label>{ content.phone}: <input type="tel"  name="tel"  /></label>
        </p>
        <p>
          <label>{ content.object}: * <select name="object[]" multiple required>
            <option value="translation">{ content.translation}</option>
            <option value="proofreading">{ content.proofreading}</option>
            <option value="proofreading">{ content.transcription}</option>
            <option value="proofreading">{ content.subtitling}</option>
            <option value="proofreading">{ content.other}</option>
          </select></label>
        </p>
        <p>
          <label>{ content.project}: * <textarea name="message" placeholder={descrform} required></textarea></label>
        </p>
{/*         <p>
        <label>{ content.upload}: <input type="file" name="file"/></label>
        </p> <div data-netlify-recaptcha="true"></div>*/}
        <p>
          <button type="submit">{ content.send}</button>
        </p>
      </form>

      <p>{ content.required }</p>
         <p><small>{ content.small }</small></p>

       </div>
       </div>
     </div>

   </section>
)}

export default Subscribe